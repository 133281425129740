<template>
  <div class="home">
    <projectListComponent/>
  </div>
</template>

<script>
// @ is an alias to /src
import projectListComponent from '@/components/projectListComponent.vue'

export default {
  name: 'ProjectsView',
  components: {
    projectListComponent
  }
}
</script>
