<template>
  <div class="home">
    <heroComponent/>

    <!-- <aboutMeComponent/> -->
    <!-- <skillsComponent/> -->
  </div>
</template>

<script>
// @ is an alias to /src
import heroComponent from '@/components/heroComponent.vue'
// import recommendationsComponent from '@/components/recommendationsComponent.vue'
// import aboutMeComponent from '@/components/autoMeComponent.vue'
// import skillsComponent from '@/components/skillsComponent.vue'

export default {
  name: 'HomeView',
  components: {
    heroComponent
  }
}
</script>
