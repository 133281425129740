import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProjectsView from '../views/ProjectsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { transition: 'fade' },
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    meta: { transition: 'fade' },
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/projects',
    name: 'projects',
    meta: { transition: 'fade' },
    component: ProjectsView
  },
  {
    path: '/gudbot',
    name: 'gudbot',
    meta: { transition: 'fade' },
    component: () => import(/* webpackChunkName: "gudbot" */ '../views/gudbotView.vue')
  },
  {
    path: '/cego',
    name: 'cego',
    component: () => import(/* webpackChunkName: "cego" */ '../views/cegoView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(), 
  routes
})

export default router
