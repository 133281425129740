<template>
  <nav class="navbar" :class="{ 'hidden': isHidden }">
    <div class="navbar-container">
      <router-link to="/" exact-active-class="active">Home</router-link>
      <router-link to="/about" exact-active-class="active">About</router-link>
      <router-link to="/Projects" exact-active-class="active">Projects</router-link>
    </div>
  </nav>
</template>

<script>
// import TagComponent from '../tags/tagComponent.vue';

export default {
  components: {
    // TagComponent
  },
  data() {
    return {
      isHidden: false,
      lastScrollY: 0
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > this.lastScrollY) {
        this.isHidden = true;
      } else {
        this.isHidden = false;
      }
      this.lastScrollY = window.scrollY;
    }
  }
};
</script>

<style scoped lang="scss">
.navbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  position: fixed;
  width: 100%;
  top: 0;
  transition: top 0.3s;
  z-index: 999;
  background-color: rgb(32, 32, 32);

  .navbar-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding-right: 100px;

    @media (max-width: 768px) {
      padding-right: 20px;
    }
  }

  .link-wrapper {
    position: relative;
    display: inline-block;
  }

  a {
    margin: 0 15px;
    padding: 10px 20px;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 5px;

    &:hover {
      background-color: #42b983;
      color: #ffffff;
    }

    &.active {
      background-color: #42b983;
      color: #ffffff;
    }
  }

  &.hidden {
    top: -100px;
  }
}
</style>
