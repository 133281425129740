<template>
  <div id="app">
    <Navbar />
    <transition @before-enter="beforeEnter" @enter="enter" @leave="leave">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Navbar from './components/navbar/navbarComponent.vue';
import anime from 'animejs/lib/anime.es.js';

export default {
  name: 'App',
  components: {
    Navbar
  },
  async mounted() {
    if (!sessionStorage.getItem('wakeupCalled')) {
      try {
        const ipResponse = await fetch('https://api.ipify.org?format=json');
        const ipData = await ipResponse.json();
        const visitorIp = ipData.ip;

        const response = await fetch('https://gud-solutions-7c34aeade02d.herokuapp.com/wakeup', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ ip: visitorIp })
        });

        const data = await response.text();
        console.log(data);
        sessionStorage.setItem('wakeupCalled', 'true');
      } catch (error) {
        console.error('Error calling wakeup endpoint:', error);
      }
    }
  },
  methods: {
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = 'translateY(50px)';
    },
    enter(el, done) {
      anime({
        targets: el,
        opacity: [0, 1],
        translateY: [50, 0],
        duration: 1000,
        easing: 'easeOutExpo',
        complete: done
      });
    },
    leave(el, done) {
      anime({
        targets: el,
        opacity: [1, 0],
        translateY: [0, 50],
        duration: 1000,
        easing: 'easeInExpo',
        complete: done
      });
    }
  }
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

body {
  background-color: rgb(32, 32, 32);
}

#app {
  font-family: "JetBrains Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: rgb(32, 32, 32);
  padding-top: 50px; 
}

/* Custom Scrollbar */
body::-webkit-scrollbar {
  width: 12px; /* Custom width */
}

body::-webkit-scrollbar-track {
  background: rgb(32, 32, 32); /* Background of the scrollbar track */
}

body::-webkit-scrollbar-thumb {
  background-color: #42b983; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Roundness of the scrollbar thumb */
  border: 3px solid rgb(32, 32, 32); /* Creates padding around the thumb */
}

.links {
  color: #FF10F0;
}
</style>
