<template>
  <div class="container-fluid text-light py-4">
    <div class="search-bar">
      <input
        type="text"
        v-model="searchQuery"
        @input="searchProjects"
        class="search-input"
        placeholder="Search projects"
      />
    </div>
    <div class="projects-section">
      <div class="projects-grid">
        <template v-if="!isLoading">
          <div
            class="project-card"
            v-for="(project, index) in topProjects"
            :key="index"
          >
            <div class="project-content">
              <h5 class="project-title" :title="project.name">{{ truncateTitle(project.name) }}</h5>
              <p class="project-description">Description: <span v-html="truncateDescription(project.description, project.html_url)"></span></p>
              <p class="project-date">Created at: {{ formatDate(project.created_at) }}</p>
              <div class="project-languages">
                <p v-for="(lang, idx) in project.languages" :key="idx">
                  {{ lang.language }}: {{ lang.percentage }}%
                </p>
              </div>
              <a v-if="project.homepage" :href="project.homepage" target="_blank" class="project-link">
                <i class="fas fa-globe"></i> View live page
              </a>
              <a :href="project.html_url" target="_blank" class="project-link">
                <i class="fab fa-github"></i> View on GitHub
              </a>
            </div>
          </div>
        </template>
        <template v-if="isLoading">
          <div class="projects-section">
            <div class="projects-grid">
                <div class="project-card skeleton-loader" v-for="n in 15" :key="n">
                  <div class="project-content">
                    <div class="skeleton skeleton-title"></div>
                    <div class="skeleton skeleton-text"></div>
                    <div class="skeleton skeleton-text"></div>
                    <div class="skeleton skeleton-text"></div>
                  </div>
                </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="projects-section">
      <div class="projects-grid">
        <template v-if="!isLoading">
          <div
            class="project-card"
            v-for="(project, index) in restProjects"
            :key="index"
          >
            <div class="project-content">
              <h5 class="project-title" :title="project.name">{{ truncateTitle(project.name) }}</h5>
              <p v-if="project.description" class="project-description">Description: <span v-html="truncateDescription(project.description, project.html_url)"></span></p>
              <p class="project-date">Created at: {{ formatDate(project.created_at) }}</p>
              <div class="project-languages">
                <p v-for="(lang, idx) in project.languages" :key="idx">
                  {{ lang.language }}: {{ lang.percentage }}%
                </p>
              </div>
              <a v-if="project.homepage" :href="project.homepage" target="_blank" class="project-link">
                <i class="fas fa-globe"></i> View live page
              </a>
              <a :href="project.html_url" target="_blank" class="project-link">
                <i class="fab fa-github"></i> View on GitHub
              </a>
            </div>
          </div>
        </template>
        <template v-if="isLoading">
          <div class="project-card skeleton-loader" v-for="n in 15" :key="n">
            <div class="project-content">
              <div class="skeleton skeleton-title"></div>
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
              <div class="skeleton skeleton-text"></div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { io } from "socket.io-client";

export default {
  data() {
    return {
      topProjects: [],
      restProjects: [],
      searchQuery: "",
      isLoading: true, // Loading state
      socket: null
    };
  },
  created() {
    this.socket = io("https://gud-solutions-7c34aeade02d.herokuapp.com/github");

    this.socket.on("connect", () => {
      this.searchProjects(); // Fetch projects on load
    });

    this.socket.on("receiveGitHubProjects", (data) => {
      this.topProjects = data.topProjects;
      this.restProjects = data.restProjects;
      this.isLoading = false; // Data has been loaded
    });

    this.socket.on("disconnect", () => {
    });
  },
  methods: {
    searchProjects() {
      this.isLoading = true; // Set loading state
      this.socket.emit("searchProjects", this.searchQuery);
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    truncateTitle(name) {
      return name.length > 25 ? `${name.substring(0, 25)}..` : name;
    },
    truncateDescription(description, link) {
      if (description.length > 125) {
        return `${description.substring(0, 125)}.. <a class="links" href='${link}' target='_blank'>read more</a>`;
      }
      return description;
    }
  },
  beforeUnmount() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
};
</script>

<style scoped>
.container-fluid {
  padding: 20px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-bar {
  width: 30%;
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.projects-section {
  margin-bottom: 40px;
}

.projects-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.project-card {
  background-color: #333;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  max-width: 300px;
}

.project-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
}

.project-title {
  font-size: 18px;
  font-weight: bold;
}

.project-description,
.project-forks,
.project-date {
  font-size: 14px;
}

.project-languages p {
  font-size: 12px;
}

.project-link {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #ffffff;
  text-decoration: none;
}

.project-link:hover {
  text-decoration: underline;
}

.fab.fa-github {
  font-size: 20px;
}

/* Skeleton Loader Styles */
.skeleton-loader {
  background-color: #444;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  width: 300px;
}

.skeleton {
  background-color: #555;
  border-radius: 4px;
  height: 20px;
  margin-bottom: 10px;
}

.skeleton-title {
  width: 80%;
  height: 24px;
}

.skeleton-text {
  width: 100%;
  height: 16px;
}

@media (max-width: 576px) {
  .search-bar {
    width: 90%;
  }
}
</style>
