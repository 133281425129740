<template>
  <div>
    <section class="hero">
      <div class="hero__content">
        <h1 class="hero__title">
          Hi, I'm <span v-html="formattedName"></span>
        </h1>
        <p class="hero__subtitle">A Full stack developer based in Aalborg</p>
        <div class="skills__content">
          <swiper
            ref="mySwiper"
            :slides-per-view="getSlidesPerView()"
            :space-between="20"
            :autoplay="autoplayOptions"
            loop
            :speed="1000"
            :effect="'slide'"
            :modules="modules"
            :disableOnInteraction="true"
            class="mySwiper"
            @mouseover="stopAutoplay"
            @mouseleave="startAutoplay"
          >
            <swiper-slide v-for="skill in skills" :key="skill" class="skills__item">
              <span>{{ skill }}</span>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref, onMounted, nextTick } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import { Autoplay, EffectFade } from 'swiper/modules';

export default {
  name: 'Hero',
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const modules = [Autoplay, EffectFade];
    const mySwiper = ref(null);

    const stopAutoplay = () => {
      if (mySwiper.value && mySwiper.value.swiper && mySwiper.value.swiper.autoplay) {
        mySwiper.value.swiper.autoplay.stop();
      }
    };

    const startAutoplay = () => {
      if (mySwiper.value && mySwiper.value.swiper && mySwiper.value.swiper.autoplay) {
        mySwiper.value.swiper.autoplay.start();
      }
    };

    const getSlidesPerView = () => {
      const width = window.innerWidth;
      if (width < 576) {
        return 4;
      } else if (width < 768) {
        return 3;
      } else if (width < 992) {
        return 4;
      } else if (width < 1200) {
        return 5;
      } else {
        return 6;
      }
    };

    onMounted(() => {
      nextTick(() => {
        if (mySwiper.value && mySwiper.value.swiper) {
          mySwiper.value.swiper.autoplay.start();
        }
      });

      window.addEventListener('resize', () => {
        if (mySwiper.value && mySwiper.value.swiper) {
          mySwiper.value.swiper.params.slidesPerView = getSlidesPerView();
          mySwiper.value.swiper.update();
        }
      });
    });

    return {
      modules,
      mySwiper,
      stopAutoplay,
      startAutoplay,
      getSlidesPerView,
    };
  },
  data() {
    return {
      name: 'Rúni',
      skills: ['JavaScript', 'Vue.js','React','three.js', 'Node.js', 'HTML', 'CSS', 'SQL', 'SCSS', 'SEQUELIZE', 'Arduino(c++)', 'Raspberry Pi','C#'],
      autoplayOptions: {
        delay: 1500,
        disableOnInteraction: false,
      },
    };
  },
  computed: {
    formattedName() {
      return this.name
        .split('')
        .map((letter) => `<span class="hero__letter">${letter}</span>`)
        .join('');
    },
  },
};
</script>

<style scoped lang="scss">
.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  color: #fff;
  text-align: center;
  padding: 20px;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__content {
    position: relative;
    z-index: 2;
    max-width: 800px;
    padding: 20px;
    margin-bottom: 200px;
  }

  &__title {
    font-size: 4rem;
    margin-bottom: 0.5rem;
    animation: fadeIn 1s ease-out;
  }

  &__letter {
    display: inline-block;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(0.9);
    }
  }

  &__subtitle {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    animation: fadeIn 1.5s ease-out;
  }

  &__button {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: #fff;
    background-color: #42b983;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
    animation: fadeIn 2s ease-out;
    margin-top: 2rem;

    &:hover {
      background-color: #38a169;
    }
  }
}

.skills {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  background-color: #f4f4f4;
  text-align: center;
  position: relative;

  &__item {
    cursor: grab;
    transition: color 300ms ease-in-out, transform 300ms ease-in-out;
    
    &:hover {
      color: mediumaquamarine;
      transform: scale(1.05);
    }

    &:active {
      cursor: grabbing;
    }
  }

  &__content {
    max-width: 800px;
    position: relative; 

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 50px;
      height: 100%;
      z-index: 999;
      pointer-events: none;
    }
  }

  &__title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    background-color: #42b983;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    margin: 0.5rem;
    cursor: grab;
    transition: color 300ms ease-in-out;
  }
}

@media (max-width: 1200px) {
  .hero__title {
    font-size: 3rem;
  }

  .hero__subtitle {
    font-size: 1.25rem;
  }
}

@media (max-width: 992px) {
  .hero__title {
    font-size: 2.5rem;
  }

  .hero__subtitle {
    font-size: 1rem;
  }

}

@media (max-width: 768px) {
  .hero__title {
    font-size: 2rem;
  }

  .hero__subtitle {
    font-size: 0.875rem;
  }

}

@media (max-width: 576px) {
  .hero__title {
    font-size: 2.5rem;
  }

  .hero__subtitle {
    font-size: 1rem;
  }
}
</style>
